import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"

import {Translate} from "../../Translate/Translate"

import styles from "./PrivateFooterTop.module.scss"

type Props = {}

const PrivateFooterTop = (props: Props): any => {
	return (
		<div className={styles.footer__top_container}>
			<div className={styles.textContainer}>
				<div className={styles.textBox}>
					<Paragraph
						content={
							<>
								<Translate capitalizeFirst>
									Switchboard
								</Translate>
								: <span>+358 10 232 7770</span>
							</>
						}
						size={"XS"}
					/>

					<Paragraph
						className={styles.textBox}
						content={
							<>
								<Translate capitalizeFirst>
									Technical customer service
								</Translate>{" "}
								/{" "}
								<Translate capitalizeFirst>Helpdesk:</Translate>{" "}
								<span>+358 10 232 7771</span>
							</>
						}
						size={"XS"}
					/>
				</div>
			</div>
		</div>
	)
}

export default PrivateFooterTop

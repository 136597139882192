import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"

import FooterBottom from "./FooterParts/PrivateFooterBottom"
import FooterTop from "./FooterParts/PrivateFooterTop"
import styles from "./PrivateFooter.module.scss"

type Props = {}

const PrivateFooterComponent = (props: Props): any => {
	return (
		<footer className={styles.container}>
			<ContainerWrapper>
				<FooterTop />
				<FooterBottom />
			</ContainerWrapper>
		</footer>
	)
}

export default PrivateFooterComponent

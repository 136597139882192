import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import {getFooterData} from "@config/modules"
import FindTranslate from "@hooks/FindTranslate"
import FooterJson from "@jsons/footerNavigation.json"
import {useRouter} from "next/router"

import styles from "./Footer.module.scss"
import FooterBottom from "./FooterParts/FooterBottom"
import FooterMiddle from "./FooterParts/FooterMiddle/FooterMiddle"
import FooterTop from "./FooterParts/FooterTop"

type Props = {}

const FooterComponent = (props: Props): any => {
	// Define the current locale using the useRouter hook
	const router = useRouter()
	const {locale} = router

	// Get data from the getFooterData function and translate it based on the current locale
	const {getDataFunction, FooterName} = getFooterData()
	const menuTranslate = FindTranslate(FooterJson, locale)

	// Get the menu data based on the translated data and the FooterName
	const footer = getDataFunction({
		data: menuTranslate,
		name: FooterName,
	})

	return (
		<footer className={styles.container}>
			<ContainerWrapper>
				<FooterTop />
				<FooterMiddle data={footer} />
				<FooterBottom />
			</ContainerWrapper>
		</footer>
	)
}

export default FooterComponent

import {Translate} from "@components/Theme/Translate/Translate"
import {TypographyType} from "@customTypes/TypographyTypes"
import Truncate from "@hooks/Truncate"
import parse from "html-react-parser"
import React from "react"

import styles from "./Description.module.scss"

export interface DescriptionType extends TypographyType {
	shortDesc?: number
}

const Description = (props: DescriptionType & JSX.IntrinsicElements["div"]) => {
	const {
		withoutPadding,
		center = false,
		fontWeight = 400,
		withoutHtml = false,
		shortDesc,
		content,
		size = "L",
		capitalize = false,
		...restProps
	} = props

	const sizes = {
		XXL: styles.description__XXL,
		XL: styles.description__XL,
		L: styles.description__L,
		M: styles.description__M,
		S: styles.description__S,
		XS: styles.description__XS,
		XXS: styles.description__XXS,
	}
	const padding = withoutPadding ? {padding: "0"} : {}

	const sizeD = sizes[size]

	const headingStyle: React.CSSProperties = {
		textAlign: center ? "center" : undefined,
		fontWeight,
		...padding,
	}

	const regex = /<[^>]+>/g

	let contentD
	if (typeof content === "string") {
		if (!withoutHtml) {
			contentD = parse(content)
		} else {
			contentD = content.replace(/<[^>]+>|&amp;/g, (match) => {
				return match === "&amp;" ? "&" : ""
			})
		}

		if (shortDesc) {
			contentD = Truncate(content.replace(regex, ""), shortDesc)
		}
	}

	let children

	if (shortDesc && props.children && typeof props.children === "string") {
		children = Truncate(props.children, shortDesc)
	}

	const styleClassName = `${styles.description} ${sizeD} ${props.className}`

	const contentL =
		typeof content === "string" ? (
			typeof contentD === "string" ? (
				<Translate capitalize={capitalize}>{contentD}</Translate>
			) : (
				contentD
			)
		) : (
			content
		)

	return (
		<div
			{...restProps}
			className={styleClassName}
			style={headingStyle}
			onClick={props.onClick}
		>
			{contentL || children}
		</div>
	)
}

export default Description

import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import {getLink} from "@hooks/PagesData/getLink"
import {useRouter} from "next/router"
import {useEffect, useState} from "react"

import ButtonComponent from "../../Button/Button"
import Link from "../../Link/Link"
import {Translate} from "../../Translate/Translate"

import styles from "./FooterTop.module.scss"

type Props = {}

const logoContainerStyle = {
	height: "80px",
	width: "100px",
}
const socialImage = {
	height: 40,
	width: 40,
}

const FooterTop = (props: Props): any => {
	const [elementExists, setElementExists] = useState(false)

	const router = useRouter()

	useEffect(() => {
		const element = document.getElementById("book-a-demo")
		if (element) {
			setElementExists(true)
		}
		return () => {
			setElementExists(false)
		}
	}, [router.pathname])

	return (
		<div className={styles.footer__top_container}>
			<div className={styles.textContainer}>
				<div className={styles.textBox}>
					<Paragraph
						content={
							<Link
								className={styles.link}
								link={"tel:+358 10 232 7770"}
							>
								<Translate capitalizeFirst>
									Switchboard:
								</Translate>{" "}
								<span>+358 10 232 7770</span>
							</Link>
						}
						size={"XS"}
					/>

					<Paragraph
						className={styles.textBox}
						content={
							<Link
								className={styles.link}
								link={"tel:+358 10 232 7771"}
							>
								<Translate capitalizeFirst>
									Technical customer service
								</Translate>{" "}
								/{" "}
								<Translate capitalizeFirst>Helpdesk:</Translate>{" "}
								<span>+358 10 232 7771</span>
							</Link>
						}
						size={"XS"}
					/>

					<div className={styles.socialLinksContainer}>
						<Link link={getLink("twitter")}>
							<NewResponsiveImage
								alt={"x"}
								containerClassName={styles.socialImageContainer}
								imageClassName={styles.socialImage}
								{...socialImage}
								src={"/logo-x-black.png"}
							/>
						</Link>
						<Link link={getLink("linkedin")}>
							<NewResponsiveImage
								alt={"linkedin"}
								containerClassName={styles.socialImageContainer}
								imageClassName={styles.socialImage}
								{...socialImage}
								src={"/107159_circle_linkedin_icon.png"}
							/>
						</Link>
						<Link link={getLink("facebook")}>
							<NewResponsiveImage
								alt={"facebook"}
								containerClassName={styles.socialImageContainer}
								imageClassName={styles.socialImage}
								{...socialImage}
								src={
									"/104458_facebook_social-media_fb_social_icon.png"
								}
							/>
						</Link>
						<Link link={getLink("youtube")}>
							<NewResponsiveImage
								alt={"youtube"}
								containerClassName={styles.socialImageContainer}
								imageClassName={styles.socialImage}
								{...socialImage}
								src={"/104445_video_youtube_icon.png"}
							/>
						</Link>
						<Link link={getLink("instagram")}>
							<NewResponsiveImage
								alt={"instagram"}
								containerClassName={styles.socialImageContainer}
								imageClassName={styles.socialImage}
								{...socialImage}
								src={"/1161954_instagram_icon.png"}
							/>
						</Link>
					</div>
				</div>
			</div>
			<div className={styles.footer__top_btn}>
				{elementExists && (
					<Link link={getLink("home")}>
						<NewResponsiveImage
							alt={"Logo"}
							height={"80"}
							src={"/DB_logo_white_RGB.svg"}
							style={logoContainerStyle}
							width={"100"}
						/>
					</Link>
				)}
				{!elementExists && (
					<>
						<Paragraph
							className={styles.CTAtext}
							content={"Ready to get started?"}
						/>
						<ButtonComponent
							buttonType={"primary"}
							link={getLink("bookADemo")}
							asLink
						>
							book a demo
						</ButtonComponent>
					</>
				)}
			</div>
		</div>
	)
}

export default FooterTop

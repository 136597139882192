import {CookieContext} from "@components/Contexts/CookieContext"
import Description from "@components/CustomTypography/Description/Description"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React, {useContext} from "react"

import ButtonComponent from "../Button/Button"

import {CheckboxState} from "./CookiesBanner"
import styles from "./CookiesCheckBox.module.scss"

type Props = {
	onChange: (checkboxName: keyof CheckboxState) => void
	checked: boolean
	content: AdvancedSideAssetMainContent
	checkboxName: keyof CheckboxState
	disabled?: boolean
}

const CookiesCheckBox = (props: Props) => {
	const {changeCookieBanner} = useContext(CookieContext)

	return (
		<div className={styles.container}>
			<label className={styles.checkboxLabel}>
				<input
					checked={props.checked}
					className={styles.checkbox}
					disabled={props.disabled}
					type={"checkbox"}
					onChange={() => props.onChange(props.checkboxName)}
				/>
				<Paragraph
					className={styles.label}
					content={props.content.title}
					size={"S"}
				/>
			</label>

			<Description
				content={
					<>
						{props.content.content} <br />
						<ButtonComponent
							buttonType={"readMore"}
							link={props.content.buttons?.[0].link}
							asLink
							onClick={changeCookieBanner}
						>
							{props.content.buttons?.[0].text}
						</ButtonComponent>
					</>
				}
			/>
		</div>
	)
}

export default CookiesCheckBox

import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Description from "@components/CustomTypography/Description/Description"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ReactPortal from "@components/ReactPortal"
import {checkCookie} from "@helpers/checkCookie"
import getValueOrThrow from "@helpers/getValueOrError"
import {getTranslatedPageData} from "@hooks/PagesData/getTranslatedPageData"
import cookieJSON from "@jsons/cookieSettings.json"
import Cookies from "js-cookie"
import React, {useEffect, useState} from "react"

import ButtonComponent from "../Button/Button"

import styles from "./CookiesBanner.module.scss"
import CookiesCheckBox from "./CookiesCheckBox"

type Props = {handleClick?: () => void}

export type CheckboxState = {
	checkbox1: boolean
	checkbox2: boolean
	checkbox3: boolean
}

export const deleteAllCookies = () => {
	const cookies = Cookies.get()
	for (const cookieName in cookies) {
		Cookies.remove(cookieName)
	}
	Cookies.remove("_ga")
	Cookies.remove("_gat")
	Cookies.remove("_gid")
}

const CookiesBanner = (props: Props) => {
	const AdvancedSideAsset = getTranslatedPageData(cookieJSON)
	const list = getValueOrThrow(AdvancedSideAsset.AdvancedSideAsset)?.[0]
	const [cookieChange, setCookieChange] = useState(false)
	const firstCheck = getValueOrThrow(list?.mainContent?.[0])
	const secondCheck = getValueOrThrow(list?.mainContent?.[1])
	const thirdCheck = getValueOrThrow(list?.mainContent?.[2])

	useEffect(() => {
		return () => {
			overflowChange()
		}
	}, [])
	const pageVisitedValue = checkCookie("pageVisited")
	const thirdPartyCookies = checkCookie("thirdPartyCookies")
	const additionalCookies = checkCookie("additionalCookies")

	const checkbox2Initial =
		typeof pageVisitedValue !== "undefined" ? !!thirdPartyCookies : true

	const [checkboxes, setCheckboxes] = useState<CheckboxState>({
		checkbox1: true,
		checkbox2: checkbox2Initial,
		checkbox3: !!additionalCookies,
	})

	const handleSaveCookies = () => {
		deleteAllCookies()

		if (checkboxes.checkbox1) {
			const expirationDate = new Date()
			expirationDate.setDate(expirationDate.getDate() + 180)
			Cookies.set("pageVisited", "1", {expires: expirationDate})
		}

		if (checkboxes.checkbox2) {
			const expirationDate = new Date()
			expirationDate.setDate(expirationDate.getDate() + 180)
			Cookies.set("thirdPartyCookies", "1", {expires: expirationDate})

			const button = document.getElementById("hs-eu-confirmation-button")
			if (button) {
				button.click()
			}
			Cookies.remove("__hs_initial_opt_in")
			Cookies.remove("__hs_opt_out")
		} else {
			Cookies.remove("_ga")
			Cookies.remove("_gat")
			Cookies.remove("_gid")
			Cookies.remove("__hs_initial_opt_in")
			Cookies.remove("__hs_opt_out")
			Cookies.remove(
				`_ga_${process.env.NEXT_PUBLIC_GA_TRACKING_CODE_FOR_DALETE}`,
			)
		}

		if (checkboxes.checkbox3) {
			const expirationDate = new Date()
			expirationDate.setDate(expirationDate.getDate() + 180)
			Cookies.set("additionalCookies", "1", {expires: expirationDate})
		}

		setCookieChange(!cookieChange)

		if (props.handleClick) {
			props?.handleClick()
			window.location.reload()
		}
	}
	useEffect(() => {}, [cookieChange])

	const handleCheckboxChange = (checkboxName: keyof CheckboxState) => {
		setCheckboxes({
			...checkboxes,
			[checkboxName]: !checkboxes[checkboxName],
		})
	}

	// Turning off/on main scroll bar, depended on open/close property
	document.body.style.overflowY = "hidden"
	const overflowChange = () => {
		document.body.style.overflowY = "scroll"
	}

	return (
		<ReactPortal wrapperId={"portal"}>
			<div className={styles.container}>
				<ContainerWrapper>
					<div className={styles.content__container}>
						<div className={styles.content__wrapper}>
							<Title content={list.sectionTitle} size={"S"} />
							<Separator size={32} />
							<Description
								content={
									<>
										{list.sectionSubtitle}
										<br />
										<ButtonComponent
											buttonType={"readMore"}
											link={list.sectionBottomButtonLink}
											asLink
										>
											{list.sectionBottomButton}
										</ButtonComponent>
									</>
								}
							/>
							<Separator size={32} />
							<div>
								<CookiesCheckBox
									checkboxName={"checkbox1"}
									checked={checkboxes.checkbox1}
									content={firstCheck}
									disabled
									onChange={handleCheckboxChange}
								/>
								<Separator size={32} />
								<CookiesCheckBox
									checkboxName={"checkbox2"}
									checked={checkboxes.checkbox2}
									content={secondCheck}
									onChange={handleCheckboxChange}
								/>
								<Separator size={32} />
								<CookiesCheckBox
									checkboxName={"checkbox3"}
									checked={checkboxes.checkbox3}
									content={thirdCheck}
									onChange={handleCheckboxChange}
								/>
							</div>
							<Separator size={32} />
							<div className={styles.saveButton}>
								<ButtonComponent onClick={handleSaveCookies}>
									Save Settings
								</ButtonComponent>
							</div>
						</div>
						{pageVisitedValue && (
							<div className={styles.exit__button}>
								<ButtonComponent
									buttonType={"exit"}
									callback={overflowChange}
									size={"large"}
									onClick={props.handleClick}
								/>
							</div>
						)}
					</div>
				</ContainerWrapper>
			</div>
		</ReactPortal>
	)
}

export default CookiesBanner

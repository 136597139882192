import {Translate} from "@components/Theme/Translate/Translate"
import {TypographyType} from "@customTypes/TypographyTypes"
import {titleType} from "@customTypes/customTypes"
import parse from "html-react-parser"
import React from "react"

import styles from "./Title.module.scss"

export interface TitleComponentType extends TypographyType {
	type?: titleType
}

const Title = (props: TitleComponentType & JSX.IntrinsicElements["h1"]) => {
	const {
		center,
		withoutPadding,
		fontWeight,
		withoutHtml,
		content,
		size,
		capitalize = true,
		...restProps
	} = props

	// Choosing the size for Title
	const sizeMap = {
		XXL: styles.title__XXL,
		XL: styles.title__XL,
		L: styles.title__L,
		M: styles.title__M,
		S: styles.title__S,
		XS: styles.title__XS,
		XXS: styles.title__XXS,
	}
	const padding = withoutPadding ? {padding: "0"} : {}

	const sizeT = sizeMap[size || "L"]

	const style = [styles.title, sizeT, props.className].join(" ")

	const headingStyle: React.CSSProperties = {
		textAlign: center ? "center" : undefined,
		fontWeight: fontWeight || 600,
		...padding,
	}

	let contentT

	if (typeof content === "string") {
		const cleanContent = content.replace(/<\/?(?:p|h[1-6])[^>]*?>/gim, "")

		if (props.withoutHtml) {
			const cleanText = cleanContent.replace(
				/<[^>]+>|&[^;]+;/g,
				(match) => {
					return match === "&amp;" ? "&" : ""
				},
			)
			contentT = cleanText
		} else {
			contentT = parse(cleanContent)
		}

		contentT =
			typeof contentT === "string" ? (
				<Translate capitalize={capitalize}>{contentT}</Translate>
			) : (
				contentT
			)
	} else if (typeof content !== "undefined") {
		contentT = content
	}

	const Tag = `h${props.type || 2}` as JSX.IntrinsicElements["h1"]

	if (contentT) {
		return (
			// @ts-expect-error
			<Tag
				data-testid={"title-test"}
				{...restProps}
				className={style}
				style={headingStyle}
			>
				{contentT}
			</Tag>
		)
	}

	return <></>
}

export default Title

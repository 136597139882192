import {createPortal} from "react-dom"

/**
 * Teleport popup on the highest lvl of HTML structure.
 */
const ReactPortal = ({
	children,
	wrapperId,
}: {
	children: any
	wrapperId: any
}) => {
	if (typeof document !== "undefined") {
		return createPortal(
			children,
			document.getElementById(wrapperId) as Element,
		)
	}

	return <></>
}
export default ReactPortal

import {getLink} from "@hooks/PagesData/getLink"
import Cookies from "js-cookie"
import {useRouter} from "next/router"
import React, {ReactNode, createContext, useEffect, useState} from "react"

export interface CookieContextProps {
	changeCookieBanner: () => void
	cookieBanner: boolean
}

export const CookieContext = createContext<CookieContextProps>({
	changeCookieBanner: () => {},
	cookieBanner: true,
})

interface CookieProviderProps {
	children: ReactNode
}

const CookieProvider = ({children}: CookieProviderProps) => {
	const router = useRouter()
	const [cookie, setCookie] = useState(() => {
		return !Cookies.get("pageVisited")
	})

	const changeCookieBanner = () => {
		setCookie((prevCookie) => !prevCookie)
	}

	useEffect(() => {
		setCookie(!Cookies.get("pageVisited"))
	}, [])

	useEffect(() => {
		if (router.pathname !== getLink("privacyPolicy")) {
			setCookie(!Cookies.get("pageVisited"))
		}
	}, [router.pathname])

	return (
		<CookieContext.Provider
			value={{
				cookieBanner: cookie,
				changeCookieBanner,
			}}
		>
			{children}
		</CookieContext.Provider>
	)
}

export default CookieProvider

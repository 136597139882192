// ###
// Contain functions routers for pages
// Functions contain config data, like name of section in JSON and function for get data from JSON
// ###

import FindTranslate, {FindTranslatePostsTemplate} from "@hooks/FindTranslate"
import {
	AdvancedSideAssetModule,
	BadgesModule,
	BlogPostsModule,
	CategoriesModule,
	ContactUsCardModule,
	CustomerStoriesModule,
	EventPostsModule,
	FAQModule,
	HeroModule,
	JobsModule,
	MediaGalleryModule,
	PricingCardModule,
	SubMenuModule,
	footerModule,
	logosModule,
	menuModule,
	metaModule,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKey"
import {
	BadgesModuleType,
	BlogPostModuleType,
	CategoryModuleType,
	CustomerStoryModuleType,
	EventPostModuleType,
	FooterModuleType,
	HeroModuleType,
	JobModuleType,
	LogosModuleType,
	MediaGalleryModuleType,
	MenuModuleType,
	ModulesData,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"

import {
	AdvancedSideAssetNameConfig,
	ContactUsCardNameConfig,
	FAQNameConfig,
	FooterNameConfig,
	HeroNameConfig,
	MenuNameConfig,
	MetaNameConfig,
	PricingCardNameConfig,
	SubMenuNameConfig,
} from "./modulesTypes"

// ! This file contain functions-routers
// ! This mean that function should contain function for preparing data set from backend to frontend
// ! Also should contain name of key in JSON for modules ("none" for POST TYPEs)

export const AdvancedSideAssetData = (): AdvancedSideAssetNameConfig => {
	return {
		AdvancedSideAssetName: "sideAssetsWithListArray",
		getDataFunction: AdvancedSideAssetModule,
	}
}

export const FAQData = (): FAQNameConfig => {
	return {
		FAQName: "faq",
		getDataFunction: FAQModule,
	}
}

export const ContactUsCardData = (): ContactUsCardNameConfig => {
	return {
		ContactUsCardName: "dreamBrokerContactUsCard",
		getDataFunction: ContactUsCardModule,
	}
}

export const PricingCardData = (): PricingCardNameConfig => {
	return {
		PricingCardName: "priceCard",
		getDataFunction: PricingCardModule,
	}
}

export const getMenuData = (): MenuNameConfig => {
	return {
		MenuName: "none",
		getDataFunction: menuModule,
	}
}

export const getFooterData = (): FooterNameConfig => {
	return {
		FooterName: "none",
		getDataFunction: footerModule,
	}
}

export const SubMenuData = (): SubMenuNameConfig => {
	return {
		SubMenuName: "subNavigationModule",
		getDataFunction: SubMenuModule,
	}
}

export const MetaData = (): MetaNameConfig => {
	return {
		MetaName: "dreamBrokerMetaData",
		getDataFunction: metaModule,
	}
}

export const HeroData = (): HeroNameConfig => {
	return {
		HeroName: "heroSection",
		getDataFunction: HeroModule,
	}
}

type FunctionMap = {
	BadgesModule: (data: ModulesData) => BadgesModuleType[]
	BlogPostsModule: (data: ModulesData) => BlogPostModuleType[]
	EventPostsModule: (data: ModulesData) => EventPostModuleType[]
	MediaGalleryModule: (data: ModulesData) => MediaGalleryModuleType[]
	JobsModule: (data: ModulesData) => JobModuleType[]
	CategoriesModule: (data: ModulesData) => CategoryModuleType[]
	menuModule: (data: ModulesData) => MenuModuleType[]
	footerModule: (data: ModulesData) => FooterModuleType[]
	logosModule: (data: ModulesData) => LogosModuleType[]
	CustomerStoriesModule: (data: ModulesData) => CustomerStoryModuleType[]
	HeroModule: (data: ModulesData) => HeroModuleType
}

const functionMap: FunctionMap = {
	BadgesModule,
	BlogPostsModule,
	EventPostsModule,
	MediaGalleryModule,
	JobsModule,
	CategoriesModule,
	menuModule,
	footerModule,
	logosModule,
	CustomerStoriesModule,
	HeroModule,
}

export function getPosts<K extends keyof FunctionMap>(
	functionName: K,
	...args: Parameters<FunctionMap[K]>
): ReturnType<FunctionMap[K]> {
	const func = functionMap[functionName]

	const router = useRouter()
	const {locale} = router

	let translatedPosts

	if (functionName === "CategoriesModule" || functionName === "menuModule") {
		translatedPosts = FindTranslate(args[0].data, locale)
	} else {
		translatedPosts = FindTranslatePostsTemplate(
			args[0].data.entries,
			locale,
		)
	}

	// @ts-expect-error
	return func({data: translatedPosts})
}

import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import ExternalIcon from "@components/icons/ExternalIcon"
import {capitalizeFirstSymbol} from "@helpers/capitalizeFirstSymbol"
import {MenuModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React from "react"

import Link from "../../Link/Link"

import styles from "./HeaderHover.module.scss"
type Props = {
	data: MenuModuleType[]
}

const HeaderHover = (props: Props) => {
	const router = useRouter()

	return (
		<div className={styles.hoveredMenu}>
			<div className={styles.hoveredMenuBox}>
				<div className={styles.hoveredMenuWrapper}>
					{/* <h2 className={styles.hoveredTitle}>
									{subMenu.menuTitle}
								</h2> */}
					<ul>
						{props.data?.map(
							(subMenu: MenuModuleType, subMenuIndex: number) => {
								// return (
								// 	<>
								// 		{subMenu.children?.map(
								// 			(
								// 				subMenuChildren: MenuModuleType,
								// 				subMenuChildrenIndex: number,
								// 			) => {

								const external = subMenu.link?.includes("http")

								const active =
									subMenu.link &&
									router.asPath.replace(/\/|#[^/]*$/g, "") ===
										subMenu.link.replace(/\/|#[^/]*$/g, "")
										? styles.active
										: ""

								return (
									<li
										key={subMenuIndex}
										className={styles.hoveredListItem}
									>
										<Link
											className={`${styles.hoveredLink} ${active}`}
											link={subMenu.link}
											withoutTarget
										>
											<span className={styles.linkTitle}>
												{capitalizeFirstSymbol(
													subMenu.menuTitle,
												)}
												{external && (
													<ExternalIcon
														className={
															styles.svgIcon
														}
													/>
												)}
											</span>

											{subMenu.subtitle && (
												<Paragraph
													className={
														styles.descriptionText
													}
													content={subMenu.subtitle}
													size={"XS"}
													withoutPadding
												/>
											)}
										</Link>
									</li>
								)
								// 		},
								// 	)}
								// </>
								// )
							},
						)}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default HeaderHover

import React, {useEffect, useState} from "react"

import styles from "./ScrollTop.module.scss"

interface ScrollToTopProps {
	showUnder?: number
}

const ScrollTop: React.FC<ScrollToTopProps> = ({showUnder = 400}) => {
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > showUnder) {
				setIsVisible(true)
			} else {
				setIsVisible(false)
			}
		}

		window.addEventListener("scroll", toggleVisibility)

		return () => window.removeEventListener("scroll", toggleVisibility)
	}, [showUnder])

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		})
	}

	return (
		<>
			{isVisible && (
				<div className={styles.container} onClick={scrollToTop}>
					<button className={styles.button}>
						<div className={styles.triangle}>
							<i className={`fas fa-chevron-up ${styles.icon}`} />
						</div>
					</button>
				</div>
			)}
		</>
	)
}

export default ScrollTop

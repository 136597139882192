import {useLocale} from "@components/Contexts/LocaleContext"
import {capitalizeFirstSymbol} from "@helpers/capitalizeFirstSymbol"
import {SubMenuLinksType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React from "react"

import Link from "../../Link/Link"

import styles from "./SubNavLink.module.scss"

type Props = {
	stylesLink: string
	data: SubMenuLinksType
	onClick?: () => void
}

const SubNavLink = (props: Props) => {
	const router = useRouter()
	const {locale} = useLocale()

	const active =
		router.asPath.replace(/\/|#[^/]*$/g, "") ===
		props.data.link.replace(/\/|#[^/]*$/g, "")
			? styles.active
			: ""
	return (
		<li className={styles.navListItem}>
			<Link
				className={`${props.stylesLink} ${active}`}
				link={props.data.link}
				withoutTarget
				onClick={props.onClick}
			>
				{locale === "en"
					? capitalizeFirstSymbol(props.data.title)
					: props.data.title}
			</Link>
		</li>
	)
}

export default SubNavLink

import {Layout} from "@customTypes/customTypes"
import {getLink} from "@hooks/PagesData/getLink"
import {useRouter} from "next/router"
import React, {ReactNode, createContext, useEffect, useState} from "react"

interface LayoutContextProps {
	changeLayout: (layout: Layout) => void
	layout: Layout
}

export const LayoutContext = createContext<LayoutContextProps>({
	changeLayout: () => {},
	layout: "default",
})

interface LayoutProviderProps {
	children: ReactNode
}

const privateRoutes: string[] = [
	getLink("privacyNotice"),
	getLink("youtubeIntegration"),
	getLink("youtubePrivacyPolicy"),
	getLink("endUserLicenseAgreement"),
]

function isRoutePrivate(currentRoute: string) {
	return privateRoutes.includes(currentRoute)
}

const LayoutProvider = ({children}: LayoutProviderProps) => {
	const router = useRouter()

	const defaultLayout: Layout = isRoutePrivate(router.pathname)
		? "private"
		: "default"

	const [layout, setLayout] = useState<Layout>(defaultLayout)

	const changeLayout = (data: Layout) => {
		setLayout(data)
	}

	useEffect(() => {
		return () => {
			setLayout(defaultLayout)
		}
	}, [router.pathname])

	return (
		<LayoutContext.Provider
			value={{
				layout,
				changeLayout,
			}}
		>
			{children}
		</LayoutContext.Provider>
	)
}

export default LayoutProvider

import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {checkCookie} from "@helpers/checkCookie"
import {useEffect} from "react"
import ReactGA from "react-ga4"

import Link from "../Link/Link"
import LinkComponent from "../Link/LinkComponent"
import {Translate} from "../Translate/Translate"

import styles from "./Button.module.scss"
import ButtonComponentType from "./ButtonComponent"

const ButtonComponent = (props: ButtonComponentType & LinkComponent) => {
	const thirdPartyCookies = checkCookie("thirdPartyCookies")

	// Callback function
	useEffect(() => {
		return () => {
			props?.callback?.()
		}
	}, [])

	const onClick = (data: any) => {
		if (typeof props.onClick !== "undefined") {
			if (thirdPartyCookies === "1") {
				ReactGA.event({
					category: "Interaction",
					action: "Button onclick",
					label: `${
						data?.target?.outerText
							? String(data.target.outerText)
							: `Button Type: ${props.buttonType}`
					}`,
				})
			}

			if (typeof window !== "undefined" && thirdPartyCookies === "1") {
				// @ts-expect-error
				const _hsq = (window._hsq = window._hsq || [])
				_hsq.push([
					"trackCustomBehavioralEvent",
					{
						name: "pe414257_interaction",
						properties: {
							label: `${
								data?.target?.outerText
									? String(data.target.outerText)
									: `Button Type: ${props.buttonType}`
							}`,
							action: "Button onclick",
						},
					},
				])
			}

			props.onClick(data)
		}
	}

	// Choosing the BUTTON TYPE based on properties, DEFAULT is primary Button
	const type = `${styles[props.buttonType || "primary"]} ${
		props.asLink ? styles.link : styles.popup
	}`

	// Defining customization params for button
	const size = styles[props.size || "default"]

	const stylesArray = [
		styles.btn,
		type,
		String(props.className),
		size,
		props.disabled ? styles.disabled : "",
		props.playIcon ? styles.play : "",
		props.expandUnactive
			? `${
					props.buttonType !== "expandedSecondary"
						? styles.expand
						: ""
			  } ${styles.unActive}`
			: "",
		props.expandActive
			? `${
					props.buttonType !== "expandedSecondary"
						? styles.expand
						: ""
			  } ${styles.active}`
			: "",
		styles[props.brand ? props.brand : "studio"],
	]

	const btn = stylesArray.join(" ")

	const children =
		typeof props.children === "string" ? (
			<Translate capitalize>{props.children}</Translate>
		) : (
			props.children
		)

	return (
		<>
			<button
				{...props}
				aria-label={
					String(props.children) !== "undefined"
						? String(props.children)
						: "icon button"
				}
				className={!props.asLink || !props.link ? btn : " "}
				id={props.id}
				type={props.action ? props.action : undefined}
				onClick={onClick}
			>
				{props.asLink && props.link ? (
					<Link
						brand={props.brand}
						className={btn}
						icon={props.icon}
						link={props.link || "/#"}
						withoutTarget={props?.withoutTarget}
					>
						{children}
					</Link>
				) : (
					<>
						{typeof props.icon !== "undefined" && (
							<NewResponsiveImage {...props.icon} />
						)}
						{children}
					</>
				)}
			</button>
		</>
	)
}

export default ButtonComponent

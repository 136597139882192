import {MetaModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {ReactNode, createContext, useEffect, useState} from "react"

interface MetaDataContextProps {
	metaData?: MetaModuleType
	changeMetaData: (data: MetaModuleType) => void
}

const metadata: MetaModuleType = {
	title: "Dream Broker",
	description:
		"Dream Broker is a Finnish and European online video software company.",
	keywords: "keyword1, keyword2, keyword3",
	author: "Dream Broker",
	ogTitle: "Dream Broker",
	ogType: "WebPage",
	ogDescription:
		"Dream Broker is a Finnish and European online video software company.",
	ogImage: "/DB_logomark.jpg",
}

export const MetaDataContext = createContext<MetaDataContextProps>({
	metaData: metadata,
	changeMetaData: () => {},
})

interface MetaDataProviderProps {
	children: ReactNode
}

export const MetaDataProvide = ({children}: MetaDataProviderProps) => {
	const [metaData, setMetaData] = useState<MetaModuleType>(metadata)
	const router = useRouter()

	const changeMetaData = (data: MetaModuleType) => {
		setMetaData(data)
	}

	useEffect(() => {
		return () => {
			setMetaData(metadata)
		}
	}, [router.pathname])

	return (
		<MetaDataContext.Provider value={{metaData, changeMetaData}}>
			{children}
		</MetaDataContext.Provider>
	)
}

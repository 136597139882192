export function capitalizeFirstSymbol(sentence: string, all?: boolean): string {
	const words = sentence.split(" ")

	const capitalizeFirstLetter = (word: string, all?: boolean): string => {
		if (word.length === 0) {
			return word
		}
		const firstLetter = word[0].toUpperCase()
		const restOfWord = word.slice(1)
		return firstLetter + restOfWord
	}

	const capitalizedWords = words.map((word, index) => {
		if (index === 0 || word.length >= 4 || (all && word.length >= 2)) {
			return capitalizeFirstLetter(word, all)
		}
		return word
	})

	return capitalizedWords.join(" ")
}

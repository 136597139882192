// Choosing the write data object from .json by searching the right locale, if locale is not found, returning en default

/**
 * Searching right translate for PAGE TYPE of json
 */
const FindTranslate = (data: any, locale?: string, isArray?: boolean) => {
	if (typeof isArray !== "undefined" && isArray) {
		let translatedData = data.filter(
			(element: any) => element.lang === locale,
		)

		if (typeof translatedData !== "undefined") {
			return translatedData
		}

		translatedData = data.filter((element: any) => element.lang === "en")
		return translatedData
	}

	let translatedData = data.find((element: any) => element.lang === locale)

	if (typeof translatedData !== "undefined") {
		return translatedData
	}

	translatedData = data.find((element: any) => element.lang === "en")
	return translatedData
}

/**
 * Searching right translate for POST TYPE of json
 *
 * (For example: Blog post, Jobs post, Career Story post, etc.)
 */

export const FindTranslatePostsTemplate = (
	data: any,
	locale?: string,
): any[] => {
	const IDsArray: any[] = []
	const result: any[] = []

	data.map((element: any, index: number) => {
		const check = IDsArray.find((element2: any) => element2 === element.id)
		if (typeof check === "undefined" && typeof element.id !== "undefined") {
			IDsArray.push(element.id)
		}
		return element
	})

	for (let index = 0; index < IDsArray.length; index++) {
		let post = data.find(
			(element: any) =>
				element.id === IDsArray[index] && element.lang === locale,
		)
		if (typeof post === "undefined") {
			post = data.find(
				(element: any) =>
					element.id === IDsArray[index] && element.lang === "en",
			)
		}
		if (typeof post !== "undefined") {
			result.push(post)
		}
	}
	return result
}
/**
 * Searching a POST TYPE by slug
 *
 * Have options to define Locale for searching
 *
 */
export const FindBySlug = (
	data: any[],
	locale?: string,
	slug?: string | string[] | undefined,
) => {
	let translatedData = data.find(
		(element: any) => element.lang === locale && element.slug === slug,
	)

	if (typeof translatedData === "undefined") {
		translatedData = data.find(
			(element: any) => element.lang === "en" && element.slug === slug,
		)
	}

	return translatedData
}

export default FindTranslate

import {FooterModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import styles from "./FooterMiddle.module.scss"
import FooterMiddleListItem from "./FooterMiddleListItem"

type Props = {
	data: FooterModuleType[]
}

const FooterMiddle = (props: Props): any => {
	return (
		<div className={styles.container}>
			<ul className={styles.big__list}>
				{props.data.map((element: FooterModuleType, index: number) => {
					if (
						typeof element.children !== "undefined" &&
						element.children.length > 0 &&
						!element.parent
					) {
						return (
							<FooterMiddleListItem key={index} data={element} />
						)
					}
					return null
				})}
			</ul>
		</div>
	)
}

export default FooterMiddle

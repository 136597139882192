import {useEffect, useState} from "react"

import styles from "./Separator.module.scss"

type FlexObject = {
	breakpoint: number
	flex: string
}

export type SeparatorType = {
	id?: any
	children?: React.ReactNode
	className?: string
	padding?: boolean
	flexFull?: boolean
	alignCenter?: boolean
	flexAround?: boolean
	flexCentered?: boolean
	wrap?: boolean
	withoutMeasure?: boolean
	customPadding?: string
	customMargin?: string
	customGap?: string
	customHeight?: string
	backgroundColor?: string
	textAlignCenter?: boolean
	flex?: boolean
	relative?: boolean
	size?: 8 | 16 | 32 | 64 | 128 | 256
	type?: "padding" | "margin"
	style?: object
	flexArray?: FlexObject[]
}

// TODO: Improve the structure? If you have any advice contact the maintainer.

const Separator = (props: SeparatorType) => {
	const [flex, setFlex] = useState<any>({})
	const sizeMap = {
		8: styles.x8,
		16: styles.x16,
		32: styles.x32,
		64: styles.x64,
		128: styles.x128,
		256: styles.x256,
	}

	const typeMap = {
		padding: styles.padding,
		margin: styles.margin,
		default: styles.default,
	}

	const type =
		typeof props.children !== "undefined"
			? typeMap[props.type ? props.type : "padding"]
			: typeMap[props.type ? props.type : "default"]

	const sizeTypeClassName = `${styles.separator} ${
		sizeMap[props.size || 64]
	} ${type}`

	const style = `${props.className} ${
		!props.withoutMeasure ? sizeTypeClassName : styles.separator
	}`

	if (typeof props.flexArray !== "undefined") {
		useEffect(() => {
			if (typeof props.flexArray !== "undefined") {
				const width = window.innerWidth

				const flexObject = props.flexArray.find(
					(flexObject) => width <= flexObject.breakpoint,
				)

				if (flexObject) {
					setFlex(flexObject.flex)
				} else {
					setFlex("0 0 33%")
				}

				const handleResize = () => {
					if (typeof props.flexArray !== "undefined") {
						const newWidth = window.innerWidth
						const newFlexObject = props.flexArray.find(
							(flexObject) => newWidth <= flexObject.breakpoint,
						)

						if (newFlexObject) {
							setFlex(newFlexObject.flex)
						} else {
							setFlex("0 0 33%")
						}
					}
				}
				window.addEventListener("resize", handleResize)

				return () => {
					window.removeEventListener("resize", handleResize)
				}
			}
		}, [props.flexArray])
	}

	const styleF = {
		...(props.flexAround && {justifyContent: "space-around"}),
		...(props.flexCentered && {justifyContent: "center"}),
		...(props.flexFull && {justifyContent: "space-between"}),
		...((props.flexAround ||
			props.flexCentered ||
			props.flexFull ||
			props.flex) && {
			display: "flex",
		}),
		...(props.alignCenter && {alignItems: "center"}),
		...(props.wrap && {flexWrap: "wrap" as "wrap"}),
		...(props.relative && {position: "relative" as "relative"}),
		...(props.customPadding && {padding: props.customPadding}),
		...(props.customHeight && {height: props.customHeight}),
		...(props.customMargin && {margin: props.customMargin}),
		...(props.customGap && {gap: props.customMargin}),
		...(props.backgroundColor && {backgroundColor: props.backgroundColor}),
		...(props.textAlignCenter && {textAlign: "center" as "center"}),
		...{flex},
		...props.style,
	}

	return (
		<div
			className={style}
			data-testid={"separator-test"}
			id={props.id}
			style={styleF}
		>
			{props.children}
		</div>
	)
}

export default Separator

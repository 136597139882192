import {useLocale} from "@components/Contexts/LocaleContext"
import {
	AdvancedSideAssetData,
	ContactUsCardData,
	FAQData,
	HeroData,
	MetaData,
	PricingCardData,
	SubMenuData,
} from "@config/modules"
import {Theme} from "@customTypes/customTypes"
import getValueOrThrow from "@helpers/getValueOrError"
import getValueOrUndefiend from "@helpers/getValueOrUndefiend"
import {useRouter} from "next/router"

import FindTranslate from "../FindTranslate"
import {
	AdvancedSideAssetModuleType,
	ContactUsCardModuleType,
	FAQModuleType,
	HeroModuleType,
	MetaModuleType,
	PricingCardModuleType,
	SubMenuModuleType,
} from "../WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import {changePageMetaData} from "./changePageMetaData"
import {changePageSubMenu} from "./changePageSubMenu"

export const getTranslatedPageData = (
	data: any,
	withSubMenu?: boolean,
	withMetaData?: boolean,
	theme?: Theme,
	customLocale?: string,
): {
	AdvancedSideAsset?: AdvancedSideAssetModuleType[]
	pricingCardData?: PricingCardModuleType[]
	faqData?: FAQModuleType[]
	contactUsCardData?: ContactUsCardModuleType[]
	subMenu?: SubMenuModuleType
	metaData?: MetaModuleType
	heroData?: HeroModuleType
	theme?: Theme
} => {
	// Define current locale
	const router = useRouter()
	const {locale} = router
	const {changeLocale: changeLocaleCustome} = useLocale()

	// Get right translate of  Page Data
	const translatedData = FindTranslate(data.entries, customLocale || locale)
	changeLocaleCustome(translatedData.lang)

	// Getting data for another sections from  JSON
	const AdvancedSideAsset = getValueOrUndefiend(() =>
		AdvancedSideAssetData().getDataFunction({
			data: translatedData,
			name: AdvancedSideAssetData().AdvancedSideAssetName,
		}),
	)

	const pricingCardData = getValueOrUndefiend(() =>
		PricingCardData().getDataFunction({
			data: translatedData,
			name: PricingCardData().PricingCardName,
		}),
	)

	const faqData = getValueOrUndefiend(() =>
		FAQData().getDataFunction({
			data: translatedData,
			name: FAQData().FAQName,
		}),
	)

	const contactUsCardData = getValueOrUndefiend(() =>
		ContactUsCardData().getDataFunction({
			data: translatedData,
			name: ContactUsCardData().ContactUsCardName,
		}),
	)

	const subMenu = getValueOrUndefiend(() =>
		SubMenuData().getDataFunction({
			data: translatedData,
			name: SubMenuData().SubMenuName,
		}),
	)

	const metaData = getValueOrUndefiend(() =>
		MetaData().getDataFunction({
			data: translatedData,
			name: MetaData().MetaName,
		}),
	)

	const heroData = getValueOrUndefiend(() =>
		HeroData().getDataFunction({
			data: translatedData,
			name: HeroData().HeroName,
		}),
	)

	if (withSubMenu && subMenu) {
		changePageSubMenu(getValueOrThrow(subMenu), theme)
	}

	if (withMetaData && metaData) {
		const metaDataCustom = getValueOrThrow(metaData)
		metaDataCustom.faq = faqData
		changePageMetaData(metaDataCustom)
	}

	return {
		AdvancedSideAsset,
		pricingCardData,
		faqData,
		contactUsCardData,
		subMenu,
		metaData,
		heroData,
		theme,
	}
}

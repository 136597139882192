/**
 * Helper function to return the input value if not undefined, otherwise throw an error.
 * @param value - The value to be checked.
 * @param errorMessage - The error message to be used when throwing an error.
 * @returns The input value if not undefined.
 * @throws Error if the input value is undefined.
 */

export default function getValueOrThrow<T>(
	value: T | undefined,
	errorMessage?: string,
): T {
	if (value === undefined) {
		throw new Error(errorMessage || "Data is undefined")
	}
	return value
}

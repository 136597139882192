import {Theme} from "@customTypes/customTypes"
import {SubMenuModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {ReactNode, createContext, useEffect, useState} from "react"

interface HeaderContextProps {
	subMenuData?: SubMenuModuleType
	theme: Theme
	changeTheme: (data: Theme) => void
	changeSubMenu: (data: SubMenuModuleType) => void
}

export const HeaderContext = createContext<HeaderContextProps>({
	subMenuData: undefined,
	theme: "studio",
	changeSubMenu: () => {},
	changeTheme: () => {},
})

interface HeaderProviderProps {
	children: ReactNode
}

export const HeaderProvider = ({children}: HeaderProviderProps) => {
	const [subMenuData, setSubMenuData] = useState<SubMenuModuleType>()
	const [theme, setTheme] = useState<Theme>("studio")

	const router = useRouter()

	const changeSubMenu = (data: SubMenuModuleType) => {
		setSubMenuData(data)
	}

	const changeTheme = (data: Theme) => {
		setTheme(data)
	}

	useEffect(() => {
		return () => {
			setSubMenuData(undefined)
			setTheme("studio")
		}
	}, [router.pathname])

	return (
		<HeaderContext.Provider
			value={{
				subMenuData,
				theme,
				changeSubMenu,
				changeTheme,
			}}
		>
			{children}
		</HeaderContext.Provider>
	)
}

import {CookieContext} from "@components/Contexts/CookieContext"
import {LayoutContext} from "@components/Contexts/LayoutContext"
import Hubspot from "@components/Hubspot/Hubspot"
import {checkCookie} from "@helpers/checkCookie"
import {isNonEmptyString} from "@hooks/utils"
import Cookies from "js-cookie"
import {AppProps} from "next/app"
import React, {useContext, useEffect, useState} from "react"
import ReactGA from "react-ga4"

import CookiesBanner from "../CookiesBanner/CookiesBanner"
import FooterComponent from "../Footer/Footer"
import NewHeader from "../Header/NewHeader"
import PrivateFooterComponent from "../PrivateFooter/PrivateFooter"
import PrivateHeader from "../PrivateHeader/PrivateHeader"
import ScrollTop from "../ScrollTop/ScrollTop"

interface LayoutProps extends AppProps {}

const gaTrackingCode = process.env.NEXT_PUBLIC_GA_TRACKING_CODE

const unInitializeGA = () => {
	ReactGA.reset()

	const cookiesToRemove = ["_ga", "_gat", "_gid"]
	cookiesToRemove.forEach((cookie) => {
		Cookies.remove(cookie)
	})
	Cookies.remove(`_ga_${process.env.NEXT_PUBLIC_GA_TRACKING_CODE_FOR_DALETE}`)
}
const initializeCookies = () => {
	if (isNonEmptyString(gaTrackingCode)) {
		ReactGA.initialize([
			{
				trackingId: gaTrackingCode,
			},
		])
	}
}

const Layout: React.FC<LayoutProps> = ({Component, pageProps, router}) => {
	const {layout} = useContext(LayoutContext)
	const {cookieBanner, changeCookieBanner} = useContext(CookieContext)
	const [pageIsLoaded, setPageIsLoaded] = useState(false)

	const thirdPartyCookies = checkCookie("thirdPartyCookies")

	if (thirdPartyCookies === "1") {
		initializeCookies()
	}

	useEffect(() => {
		if (!pageIsLoaded) {
			setPageIsLoaded(true)
		}
		if (thirdPartyCookies !== "1") {
			unInitializeGA()
		}

		const clickHandler = (event: MouseEvent) => {
			const target = event.target as HTMLElement
			if (target.id === "cookieSettings") {
				changeCookieBanner()
			}
		}

		document.addEventListener("click", clickHandler)

		return () => {
			document.removeEventListener("click", clickHandler)
		}
	}, [])

	useEffect(() => {
		onRouteChangeComplete(router.pathname)
		if (thirdPartyCookies !== "1") {
			unInitializeGA()
		}

		return () => {
			if (thirdPartyCookies !== "1") {
				unInitializeGA()
			}
		}
	}, [router.pathname, changeCookieBanner])

	const onRouteChangeComplete = (url: string) => {
		if (isNonEmptyString(gaTrackingCode) && thirdPartyCookies === "1") {
			ReactGA.send(url)
		}
	}

	return (
		<>
			<Hubspot />
			{layout === "default" && <NewHeader />}
			{layout === "private" && <PrivateHeader />}
			<main>
				<ScrollTop showUnder={400} />
				<Component {...pageProps} />
				{pageIsLoaded && cookieBanner && (
					<CookiesBanner handleClick={changeCookieBanner} />
				)}
			</main>
			{layout === "default" && <FooterComponent />}
			{layout === "private" && <PrivateFooterComponent />}
		</>
	)
}

export default Layout

import {HeaderContext} from "@components/Contexts/HeaderContext"
import ExternalIcon from "@components/icons/ExternalIcon"
import {MenuModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {useContext, useEffect} from "react"
import {CSSTransition, TransitionGroup} from "react-transition-group"

import Link from "../../Link/Link"
import HeaderHover from "../HeaderHover/HeaderHover"

import styles from "./HeaderLink.module.scss"

type Props = {
	data: MenuModuleType
	asLink?: boolean
	linkClassName?: string
	asExpanded?: {
		setIsShown: (navBarIndex: number) => void
		isShown: number
		navBarIndex: number
	}
}

const HeaderLink = (props: Props) => {
	const asExpanded = props.asExpanded
	const {theme} = useContext(HeaderContext)

	const router = useRouter()
	const active =
		props.data.link &&
		router.asPath.replace(/\/|#[^/]*$/g, "") ===
			props.data.link.replace(/\/|#[^/]*$/g, "")
			? styles.active
			: ""

	let activeExpanded = ""

	props.data.children?.map((element: MenuModuleType) => {
		if (
			element.link &&
			router.asPath
				.replace(/\/|#[^/]*$/g, "")
				.includes(
					element.link
						?.replace("overview", "")
						.replace(/\/|#[^/]*$/g, ""),
				)
		) {
			activeExpanded = styles.active
		}
		return element
	})

	useEffect(() => {
		asExpanded?.setIsShown(-1)
	}, [router])

	const external = props.data.link?.includes("http")

	return (
		<li
			className={styles.navListItem}
			onMouseLeave={() => asExpanded?.setIsShown(-1)}
		>
			{props.asLink && (
				<Link
					className={`${styles.link} ${styles[theme]} ${
						external ? styles.external : ""
					}  ${active} ${props.linkClassName} `}
					link={props.data.link}
					withoutTarget
				>
					{props.data.menuTitle}
					{external && <ExternalIcon className={styles.svgIcon} />}
				</Link>
			)}

			{asExpanded && (
				<>
					<div
						className={`${styles.link} ${styles[theme]}  ${
							styles.expanded
						} ${
							asExpanded.isShown === asExpanded.navBarIndex
								? styles.shown
								: ""
						}  ${props.linkClassName} ${activeExpanded} `}
						onMouseEnter={() =>
							asExpanded.setIsShown(asExpanded.navBarIndex)
						}
					>
						{props.data.menuTitle}
					</div>
					<TransitionGroup>
						{asExpanded.isShown === asExpanded.navBarIndex &&
							props.data.children && (
								<CSSTransition
									classNames={{
										enter: styles.listEnter,
										enterActive: styles.listEnterActive,
										exit: styles.listExit,
										exitActive: styles.listExitActive,
									}}
									timeout={300}
								>
									<HeaderHover data={props.data.children} />
								</CSSTransition>
							)}
					</TransitionGroup>
				</>
			)}
		</li>
	)
}

export default HeaderLink

import {MetaDataContext} from "@components/Contexts/MetaDataContext "
import {useRouter} from "next/router"
import {useContext, useEffect} from "react"

import {MetaModuleType} from "../WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

export const changePageMetaData = (data: MetaModuleType) => {
	const router = useRouter()
	const {locale} = router

	const {changeMetaData} = useContext(MetaDataContext)

	useEffect(() => {
		changeMetaData(data)
	}, [locale])
}

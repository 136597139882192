import React from "react"

import styles from "./ContainerWrapper.module.scss"
import ContainerWrapperComponent from "./ContainerWrapperComponent"

const ContainerWrapper = (props: ContainerWrapperComponent): any => {
	// Defining alight for content in Container (Default centre)
	const position =
		props.position === "left"
			? styles.left
			: props.position === "right"
			? styles.right
			: styles.default

	// Summarize of styles
	const style = String(styles.container) + " " + String(position)

	return (
		<div
			className={`${style} ${props.className}`}
			id={props.id}
			style={props.style}
		>
			{props.children}
		</div>
	)
}

export default ContainerWrapper

import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import React from "react"

import styles from "./PrivateHeader.module.scss"
type Props = {}

const PrivateHeader = (props: Props) => {
	return (
		<>
			<header className={`${styles.header} ${styles.show} `}>
				<div>
					<ContainerWrapper>
						<div className={styles.headerContainer}>
							<div className={styles.navBox}>
								<div className={styles.logoWrapper}>
									<div className={styles.logoBox}>
										<NewResponsiveImage
											alt={"Logo"}
											height={100}
											imageClassName={styles.logo}
											src={"/DB_logo_RGB.svg"}
											width={100}
											withoutContainer
										/>
									</div>
									<div
										className={`${styles.headerText} ${styles.tablet}`}
									>
										Online video software company
									</div>
								</div>
							</div>
						</div>
					</ContainerWrapper>
				</div>
			</header>
		</>
	)
}

export default PrivateHeader

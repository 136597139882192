import getValueOrThrow from "@helpers/getValueOrError"
import links from "@jsons/links.json"

export const getLink = (slug: string): string => {
	const result = getValueOrThrow(
		links[0].categories.find((element: any, index: number) => {
			return element.dbLink[0].pageSlug === slug
		}),
		`Wrong link ${slug}`,
	)

	return result.dbLink[0].link
}

const debounce = <T extends (...args: any[]) => void>(
	func: T,
	delay: number,
): ((...args: Parameters<T>) => void) => {
	let timerId: NodeJS.Timeout
	return (...args: Parameters<T>) => {
		clearTimeout(timerId)
		timerId = setTimeout(() => {
			func(...args)
		}, delay)
	}
}

export default debounce

import {checkCookie} from "@helpers/checkCookie"
import {useRouter} from "next/router"
import React, {useEffect} from "react"

const Hubspot = () => {
	const router = useRouter()
	const thirdPartyCookies = checkCookie("thirdPartyCookies")

	useEffect(() => {
		if (typeof window !== "undefined" && thirdPartyCookies === "1") {
			// @ts-expect-error
			const _hsq = (window._hsq = window._hsq || [])
			_hsq.push(["setPath", router.pathname])
		}
	}, [])

	useEffect(() => {
		if (typeof window !== "undefined" && thirdPartyCookies === "1") {
			// @ts-expect-error
			const _hsq = (window._hsq = window._hsq || [])
			_hsq.push(["setPath", router.pathname])
			_hsq.push(["trackPageView"])
		}
	}, [router.pathname])

	return (
		<>
			<script
				id={"hs-script-loader"}
				src={"//js.hs-scripts.com/414257.js"}
				type={"text/javascript"}
				async
				defer
			/>
		</>
	)
}

export default Hubspot

export const isNonEmptyString = (string: unknown): string is string =>
	typeof string === "string" && string.length !== 0

export const validateEmail = (email: string): boolean => {
	// eslint-disable-next-line no-useless-escape
	const re =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

import {HeaderContext} from "@components/Contexts/HeaderContext"
import {Theme} from "@customTypes/customTypes"
import {useRouter} from "next/router"
import {useContext, useEffect} from "react"

import {SubMenuModuleType} from "../WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

export const changePageSubMenu = (data: SubMenuModuleType, theme?: Theme) => {
	const router = useRouter()
	const {locale} = router

	const {changeSubMenu, changeTheme} = useContext(HeaderContext)

	useEffect(() => {
		changeSubMenu(data)
		changeTheme(theme || "studio")
	}, [locale])
}

import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {Theme} from "@customTypes/customTypes"
import {
	SubMenuLinksType,
	SubMenuModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React, {useState} from "react"
import {CSSTransition, TransitionGroup} from "react-transition-group"

import ButtonComponent from "../../Button/Button"
import Link from "../../Link/Link"
import SubNavLink from "../HeaderComponents/SubNavLink"

import styles from "./SubNavigation.module.scss"

type Props = {
	data: SubMenuModuleType
	header: boolean
	theme: Theme
}

const SubNavigationMobile = (props: Props) => {
	const [active, setActive] = useState(false)

	const changeActive = () => {
		setActive(!active)
	}

	const button = props.data.links.find((element) => element.asButton)
	const smallButtons = props.data.links.filter(
		(element) => element.id !== button?.id,
	)

	const stylesLink =
		button &&
		`${!button.asButton ? styles.link : styles.button} ${
			button.asPrimary || !props.header
				? styles.primary
				: styles.secondary
		}`

	return (
		<>
			<div
				className={`${styles.subHeader} ${
					props.header ? styles.show : styles.hide
				} ${props.theme}`}
			>
				<ContainerWrapper>
					<div className={styles.headerContainer}>
						<div className={styles.navBox}>
							<div className={styles.logoWrapper}>
								<ButtonComponent
									buttonType={"unstyled"}
									className={`${styles.logoBox} ${styles.second}`}
									expandActive={active}
									expandUnactive={!active}
									onClick={changeActive}
								>
									{props.data.subLogo && (
										<NewResponsiveImage
											withoutContainer
											{...props.data.subLogo}
											imageClassName={styles.logo}
										/>
									)}

									{!props.data.subLogo && (
										<NewResponsiveImage
											alt={"Logo"}
											height={100}
											imageClassName={styles.logo}
											src={"/DB_logo_RGB.svg"}
											width={100}
											withoutContainer
										/>
									)}
								</ButtonComponent>
							</div>

							{button && (
								<div className={styles.logoWrapper}>
									<Link
										className={`${stylesLink} ${active}`}
										link={button.link}
										withoutTarget
										onClick={
											active ? changeActive : () => {}
										}
									>
										{button.title}
									</Link>
								</div>
							)}
						</div>
					</div>
				</ContainerWrapper>

				<TransitionGroup>
					{active && (
						<CSSTransition
							classNames={{
								enter: styles.menuEnter,
								enterActive: styles.menuEnterActive,
								exit: styles.menuExit,
								exitActive: styles.menuExitActive,
							}}
							timeout={300}
						>
							<nav className={styles.navWrapper}>
								<ul className={styles.navList}>
									{smallButtons.map(
										(
											navBar: SubMenuLinksType,
											navBarIndex: number,
										) => {
											const stylesLink = `${
												!navBar.asButton
													? styles.link
													: styles.button
											} ${
												navBar.asPrimary ||
												!props.header
													? styles.primary
													: styles.secondary
											}`

											return (
												<SubNavLink
													key={navBarIndex}
													data={navBar}
													stylesLink={stylesLink}
													onClick={changeActive}
												/>
											)
										},
									)}
								</ul>
							</nav>
						</CSSTransition>
					)}
				</TransitionGroup>
			</div>
		</>
	)
}

export default SubNavigationMobile

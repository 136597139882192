// ###
// Contain functions which searching for needed section and formatting data,
// also giving the strict type for this data to make easier pulling into the components
// ###

import {getLink} from "../../PagesData/getLink"

import {
	getCategoriesFormat,
	getImageUrl,
} from "./GetComponentDataByKeyAdditionalFunctions"
import {
	AdditionalLinkType,
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
	AssetType,
	BackendButton,
	BadgesModuleType,
	BlogPostModuleType,
	CategoryModuleType,
	ContactUsCardModuleType,
	CustomerStoryModuleType,
	EventPostModuleType,
	FAQModuleType,
	FooterModuleType,
	HeroModuleType,
	JobModuleType,
	LogosModuleType,
	MediaGalleryModuleType,
	MediaType,
	MenuModuleType,
	MetaModuleType,
	ModulesData,
	PricingCardFeature,
	PricingCardModuleType,
	SubMenuModuleType,
} from "./GetComponentDataByKeyTypes"

// ###
// Down are formatting functions
// ###

/**
 * Creating the structure for blog Posts module from backend
 */
export const BlogPostsModule = (data: ModulesData): BlogPostModuleType[] => {
	const result: BlogPostModuleType[] = []

	data.data.map((element: any, index: number) => {
		result.push(FormatBlogPostData(element))
		return element
	})

	return result
}

/**
 * Creating the structure for Event Posts module from backend
 */
export const EventPostsModule = (data: ModulesData): EventPostModuleType[] => {
	const result: EventPostModuleType[] = []

	data.data.map((element: any, index: number) => {
		result.push(FormatEventPostData(element))
		return element
	})

	return result
}

/**
 * Formatting single Blog Post data
 */
export const FormatEventPostData = (element: any): EventPostModuleType => {
	const thumbui: AssetType = element.dbEventPreview[0]
		? {
				type: "image",
				src: getImageUrl(element.dbEventPreview[0].filename),
				alt: element.dbEventPreview[0].title,
				filename: element.dbEventPreview[0].filename,
				width: element.dbEventPreview[0].width,
				height: element.dbEventPreview[0].height,
		  }
		: {
				type: "image",
				src: getImageUrl("DB_logo_RGB.png"),
				alt: "thumbui",
				filename: "DB_logo_RGB.png",
				width: "2457",
				height: "1853",
		  }

	const upperTitle = new Date(element.postDate * 1000).toLocaleDateString(
		undefined,
		{
			year: "numeric",
			month: "long",
			day: "numeric",
		},
	)
	const startDate = new Date(
		element.dbEventStartDate * 1000,
	).toLocaleDateString(undefined, {
		year: "numeric",
		month: "long",
		day: "numeric",
	})
	const endDate = new Date(element.dbEndDate * 1000).toLocaleDateString(
		undefined,
		{
			year: "numeric",
			month: "long",
			day: "numeric",
		},
	)

	const category: CategoryModuleType[] = getCategoriesFormat(
		element.dbEventCategory,
	)
	return {
		id: element.id,
		categories: [{category: {options: category}}],
		category,
		type: getCategoriesFormat(element.dbEventType),
		location: getCategoriesFormat(element.dbEventLocation),
		title: element.title,
		thumbui,
		slug: element.slug,
		upperTitle,
		content: element.dbEventText.content,
		link: "/event/" + String(element.slug),
		state: element.dbEventState,
		startDate,
		endDate,
	}
}

/**
 * Formatting single Blog Post data
 */
export const FormatBlogPostData = (element: any): BlogPostModuleType => {
	const thumbui: AssetType = element.dbBlogImage[0]
		? {
				type: "image",
				src: getImageUrl(element.dbBlogImage[0].filename),
				alt: element.dbBlogImage[0].title,
				filename: element.dbBlogImage[0].filename,
				width: element.dbBlogImage[0].width,
				height: element.dbBlogImage[0].height,
		  }
		: {
				type: "image",
				src: getImageUrl("DB_logo_RGB.png"),
				alt: "thumbui",
				filename: "DB_logo_RGB.png",
				width: "2457",
				height: "1853",
		  }

	const upperTitle = new Date(element.postDate * 1000).toLocaleDateString(
		undefined,
		{
			year: "numeric",
			month: "long",
			day: "numeric",
		},
	)

	const category: CategoryModuleType[] = getCategoriesFormat(
		element.dbBlogCategory,
	)

	return {
		id: element.id,
		category,
		title: element.title,
		thumbui,
		slug: element.slug,
		categories: [{category: {options: category}}],
		upperTitle,
		content: element.dbBlogContent.content,
		link: getLink("newsArchive") + "/" + String(element.slug),
	}
}

/**
 * Creating the structure for badges module from backend
 */
export const BadgesModule = (data: ModulesData): BadgesModuleType[] => {
	const result: BadgesModuleType[] = []

	data.data.map((element: any, index: number) => {
		result.push(FormatBadgesData(element))
		return element
	})

	return result
}

/**
 * Formatting single Badges data
 */
export const FormatBadgesData = (element: any): BadgesModuleType => {
	const preview: AssetType = {
		type: "image",
		src: getImageUrl(element.dreamBrokerBadgesImage[0].filename),
		alt: element.dreamBrokerBadgesImage[0].title,
		filename: element.dreamBrokerBadgesImage[0].filename,
		width: element.dreamBrokerBadgesImage[0].width,
		height: element.dreamBrokerBadgesImage[0].height,
	}

	return {
		id: element.id,
		title: element?.dreamBrokerBadgesTitle,
		preview,
		slug: element.slug,
		content: element?.dreamBrokerBadgesDescription?.content,
		link: element?.dreamBrokerBadgesLink,
	}
}

/**
 *  Creating the structure for Media Gallery  module from backend
 */
export const MediaGalleryModule = (
	data: ModulesData,
): MediaGalleryModuleType[] => {
	const result: MediaGalleryModuleType[] = []

	data.data.map((element: any, index: number) => {
		result.push(FormatMediaGalleryModule(element))
		return element
	})

	return result
}
/**
 * Formatting single Media Gallery data
 */
export const FormatMediaGalleryModule = (
	element: any,
): MediaGalleryModuleType => {
	const media: MediaType[] = []

	const additionalLink: AdditionalLinkType | undefined =
		typeof element.additionalLink[0] !== "undefined"
			? {
					id: element.additionalLink[0].id as number,
					link: element.additionalLink[0].mainLink as string,
					buttonText: element.additionalLink[0].buttonText as string,
			  }
			: element.additionalLink[0]

	element.previewAsset.map((element: any, index: number) => {
		media.push({
			inside: {
				alt: getImageUrl(element.filename),
				type: "image",
				filename: element.filename,
				src: element.title,
				width: element.width,
				height: element.height,
			},
			preview: {
				src: getImageUrl(element.filename),
				type: "image",
				alt: element.title,
				filename: element.filename,
				width: element.width,
				height: element.height,
			},
			subtitle: element.filename,
		})
		return element
	})
	return {
		id: element.id,
		title: element.mainTitle,
		additionalLink,
		media,
	}
}
/**
 * Creating the structure for Jobs posts from backend
 */
export const JobsModule = (
	data: ModulesData,
	categories?: string[],
): JobModuleType[] => {
	const result: JobModuleType[] = []

	data.data.map((element: any, index: number) => {
		if (
			typeof categories !== "undefined" &&
			categories.includes(element.jobCategory[0].slug)
		) {
			result.push(FormatJobModule(element))
			return element
		} else if (typeof categories === "undefined") {
			result.push(FormatJobModule(element))
			return element
		}
		return undefined
	})

	return result
}
/**
 * Formatting single Job data
 */
export const FormatJobModule = (element: any): JobModuleType => {
	const preview: AssetType = {
		type: "video",
		src: element.jobIframeLink,
		alt: element.title,
		filename: element.title,
		width: 1920,
		height: 1080,
	}
	const department: CategoryModuleType[] = getCategoriesFormat(
		element.jobCategory,
	)
	const location: CategoryModuleType[] = getCategoriesFormat(
		element.jobLocation,
	)

	return {
		id: element.id,
		title: element.title,
		slug: element.slug,
		preview,
		inside: preview,
		department,
		categories: [
			{department: {options: department}},
			{location: {options: location}},
		],
		content: element.jobContent?.content,
		location,
		responsibility: element.responsibility,
	}
}
/**
 * Creating the structure for Categories from backend
 */
export const CategoriesModule = (data: ModulesData): CategoryModuleType[] => {
	const category: CategoryModuleType[] = getCategoriesFormat(
		data.data.categories,
	)

	return category
}

/**
 * Creating the structure for Nav menus
 */
export const menuModule = (data: any): MenuModuleType[] => {
	const menu = data.data.categories.map((element: any, index: number) => {
		return menuFormatting(element)
	})

	return menu
}

const menuFormatting = (data: any): MenuModuleType => {
	const result: any = {
		id: data.id,
		slug: data?.slug,
		menuTitle: data?.menuTitle,
		subtitle: data?.categorySubtitle,
		link: linkFormatter(data?.menuLinkSelect),
		parent: data?.parent,
		children: data.children?.map((element: any, index: number) => {
			return menuFormatting(element)
		}),
	}
	return result as MenuModuleType
}

/**
 * Creating the structure for Nav menus
 */
export const metaModule = (data: any): MetaModuleType => {
	const section = eval(`data.data.${data.name}`)[0]

	return {
		title: section.mainTitle,
		description: section.description,
		keywords: section.keywords,
		author: "Dream Broker",
		ogTitle: section.ogtitle,
		ogType: section.ogtype,
		ogDescription: section.ogdescription,
		ogImage: getImageUrl(section.ogimage[0].filename),
	}
}

/**
 * Creating the structure for Footer menus
 */
export const footerModule = (data: any): FooterModuleType[] => {
	const menu = data.data.categories.map((element: any, index: number) => {
		return menuFooterFormatting(element)
	})

	return menu
}

const menuFooterFormatting = (data: any): FooterModuleType => {
	const result: any = {
		id: data.id,
		slug: data?.slug,
		menuTitle: data?.menuTitle,
		subtitle: data?.subtitle,
		link: linkFormatter(data?.link),
		parent: data?.parent,
		children: data.children?.map((element: any, index: number) => {
			return menuFooterFormatting(element)
		}),
	}
	return result as FooterModuleType
}

/**
 * Creating the structure for Nav menus
 */
export const logosModule = (data: any): LogosModuleType[] => {
	const logos: LogosModuleType[] = data.data.map(
		(element: any, index: number) => {
			return {
				id: element.id,
				slug: element.slug,
				title: element.dbCustomerLogosName,
				link: element.dbCustomerLogosLink,
				logo: {
					filename: element.dbCustomerLogosImage[0].filename,
					src: getImageUrl(element.dbCustomerLogosImage[0].filename),
					type: "image",
					alt: element.dbCustomerLogosImage[0].title,
					width: element.dbCustomerLogosImage[0].width,
					height: element.dbCustomerLogosImage[0].height,
				},
			}
		},
	)
	return logos
}

export const FAQModule = (data: ModulesData): FAQModuleType[] => {
	const section = eval("data.data." + String(data.name))

	const FAQ: FAQModuleType[] = section[0].faq.map(
		(element: any, index: number) => {
			return {
				question: element.question,
				answer: element.answer.content,
			}
		},
	)

	return FAQ
}

export const ContactUsCardModule = (
	data: ModulesData,
): ContactUsCardModuleType[] => {
	const section = eval("data.data." + String(data.name))

	const ContactUsCards: ContactUsCardModuleType[] = section.map(
		(element: any, index: number) => {
			return {
				cards: element?.contactUsCards.map((element3: any) => {
					return {
						title: element3?.cardTitle,
						subtitle: element3?.cardSubtitle,
						cardDetails: element3?.cardDetails.map(
							(element2: any) => {
								return {
									name: element2.personName,
									position: element2.position?.content,
									email: element2?.email?.content,
									phone: element2?.phone,
								}
							},
						),
					}
				}),
			}
		},
	)

	return ContactUsCards
}
/**
 * Creating the structure for Footer menus
 */
export const PricingCardModule = (
	data: ModulesData,
): PricingCardModuleType[] => {
	const section = eval("data.data." + String(data.name))

	const priceCards: PricingCardModuleType[] = section[0].priceCard.map(
		(element: any, index: number) => {
			const features: PricingCardFeature[] = element.features.map(
				(feature: any, index: number) => {
					return {
						title: feature.mainTitle,
						additionalInformation: feature.additionalInformation,
						href: linkFormatter(feature.linkToFeature),
					}
				},
			)

			const result: PricingCardModuleType = {
				title: element.mainTitle,
				price: element.price?.[0],
				time: element.time,
				description: element.description,
				beforePrice: element.beforePrice,
				pricePerUser: element.pricePerUser?.[0],
				bodyText: element.bodyText,
				features,
				buttonTitle: element.buttonTitle,
				buttonLink: element.buttonLink,
			}

			return result
		},
	)

	return priceCards
}

/**
 * Creating the structure for Advanced Side Asset List module from backed
 */
// ! Be careful to change output data and variables naming.
// ! This module are using in 80% of components.
export const AdvancedSideAssetModule = (
	data: ModulesData,
	limit?: number,
): AdvancedSideAssetModuleType[] => {
	const section = eval("data.data." + String(data.name))
	const sections: AdvancedSideAssetModuleType[] = []

	section[0].sideAssetsWithListArray.map((element: any, index: number) => {
		const mainContent: AdvancedSideAssetMainContent[] = []

		for (
			let index = 0;
			index <= element.mainContent.length &&
			(typeof limit !== "undefined" ? index < limit : true);
			index++
		) {
			if (element.mainContent[index]) {
				const buttons = element.mainContent[index].buttons.map(
					(elementL: any, index: number) => {
						const icon = elementL?.icon?.[0]
							? elementL?.icon
							: undefined

						const brand = elementL.brand ? elementL.brand : "studio"

						const temporaryButton: BackendButton = {
							text: elementL.text,
							linkPopup: elementL.linkPopup,
							link: linkFormatter(elementL?.buttonlinklist),
							buttonType: elementL.ButtonType,
							size: elementL.size,
							disabled: elementL.disabled,
							playIcon: elementL.playIcon,
							icon: getAdvancedSideAssetModuleAsset({
								image: icon,
							}),
							brand,
						}
						return temporaryButton
					},
				)

				const linkModifier = element.mainContent[
					index
				]?.sectionid?.find(
					(element: any) => element?.parent?.slug === "modifiers",
				)?.dbLink?.[0]?.link

				const result: AdvancedSideAssetMainContent = {
					upperTitle: element.mainContent[index]?.upperTitle?.content,
					title: element.mainContent[index]?.mainTitle?.content,
					sectionid: linkModifier,
					subtitle: element.mainContent[index]?.mainSubtitle?.content,
					content: element.mainContent[index]?.description?.content,
					preview: getAdvancedSideAssetModuleAsset(
						element.mainContent[index].assets[0],
					),
					inside: getAdvancedSideAssetModuleAsset(
						element.mainContent[index]?.insideContent?.[0]
							?.insideAssets?.[0]?.insideAssets?.[0],
					),
					buttons,
					insideContent: {
						title: element.mainContent[index]?.insideContent?.[0]
							?.insideContent?.[0]?.insideTitle?.content,
						subtitle:
							element.mainContent[index]?.insideContent?.[0]
								?.insideContent?.[0]?.insideSubtitle?.content,

						content:
							element.mainContent[index]?.insideContent?.[0]
								?.insideContent?.[0]?.insideDescription
								?.content,

						inside: getAdvancedSideAssetModuleAsset(
							element.mainContent[index]?.insideContent?.[0]
								?.insideAssets?.[0]?.insideAssets?.[0],
						),
					},
				}

				mainContent.push(result)
			}
		}

		return sections.push({
			sectionTitle:
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionTop?.[0]?.sectionTitle,
			sectionSubtitle:
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionTop?.[0]?.sectionSubtitle,
			sectionBottomTitle:
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionBottom?.[0]?.sectionTitle,
			sectionBottomSubtitle:
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionBottom?.[0]?.sectionSubtitle,
			sectionBottomButton:
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionButton?.[0]?.buttonText,
			sectionBottomButtonLink: linkFormatter(
				element?.sectionContent?.[0]?.sectionContent?.[0]
					?.sectionButton?.[0]?.linkList,
			),
			expanded: element.expanded,
			mainContent,
		})
	})

	return sections
}

export function linkFormatter(data: any) {
	let link: string = ""

	const linkPage = data?.find(
		(element: any) => element?.parent?.slug === "pages",
	)?.dbLink?.[0]?.link

	link = link.concat(typeof linkPage === "undefined" ? "" : linkPage)

	const linkPost = data?.find(
		(element: any) => element?.parent?.slug === "posts",
	)?.dbLink?.[0]?.link

	link = link.concat(typeof linkPost === "undefined" ? "" : linkPost)

	const linkFilters = data
		?.filter((element: any) => element?.parent?.slug === "filters")
		?.map((element: any) => element?.dbLink?.[0]?.link)
		?.filter((link: any) => typeof link === "string")

	if (linkFilters?.length > 0) {
		link = link.concat("?")
		link = link.concat(linkFilters)
	}

	const linkModifier = data?.find(
		(element: any) => element?.parent?.slug === "modifiers",
	)?.dbLink?.[0]?.link

	link = link.concat(typeof linkModifier === "undefined" ? "" : linkModifier)

	return link === "" ? undefined : link
}

export const HeroModule = (data: ModulesData): HeroModuleType => {
	const section = eval("data.data." + String(data.name))

	const desktopAsset: undefined | AssetType = getAdvancedSideAssetModuleAsset(
		section?.[0]?.desktopAsset?.[0],
	)
	const mobileAsset: undefined | AssetType = getAdvancedSideAssetModuleAsset(
		section?.[0]?.mobileAsset?.[0],
	)

	const rightSideAsset: undefined | AssetType =
		getAdvancedSideAssetModuleAsset(section?.[0]?.rightSideAsset?.[0])

	const beforbeforeTitlesIconState = section?.[0]?.beforeTitlesIcon?.[0]
		? section?.[0]?.beforeTitlesIcon
		: undefined

	const beforeTitlesIcon: undefined | AssetType =
		getAdvancedSideAssetModuleAsset({
			image: beforbeforeTitlesIconState,
		})

	const title = section?.[0]?.mainTitle?.content
	const subtitle = section?.[0]?.subtitle?.content

	const insideContent = {
		inside: getAdvancedSideAssetModuleAsset(
			section?.[0]?.insideContent?.[0]?.insideAssets?.[0]
				?.insideAssets?.[0],
		),
	}

	const buttons = section?.[0]?.buttons?.map(
		(elementL: any, index: number) => {
			const icon = elementL?.icon?.[0] ? elementL?.icon : undefined
			const brand = elementL.brand ? elementL.brand : "studio"

			const temporaryButton: BackendButton = {
				text: elementL?.text,
				linkPopup: elementL?.linkPopup,
				link: linkFormatter(elementL?.buttonlinklist),
				buttonType: elementL.ButtonType,
				size: elementL?.size,
				disabled: elementL?.disabled,
				playIcon: elementL?.playIcon,
				icon: getAdvancedSideAssetModuleAsset({
					image: icon,
				}),
				brand,
			}
			return temporaryButton
		},
	)

	const result: HeroModuleType = {
		title,
		subtitle,
		desktopAsset,
		mobileAsset,
		rightSideAsset,
		beforeTitlesIcon,
		buttons,
		insideContent,
	}

	return result
}

/**
 * Creating the structure for Advanced Side Asset List module from backed
 */
// ! Be careful to change output data and variables naming.
// ! This module are using in 80% of components.
export const SubMenuModule = (data: ModulesData): SubMenuModuleType => {
	const section = eval("data.data." + String(data.name))

	const SubNavigationModuleSubNavigation = section.find(
		(element: any) =>
			element.__typename === "SubNavigationModuleSubNavigation",
	)
	const SubNavigationModuleAdditionalLogo = section.find(
		(element: any) =>
			element.__typename === "SubNavigationModuleAdditionalLogo",
	)

	let logo: AssetType | undefined
	if (typeof SubNavigationModuleAdditionalLogo !== "undefined") {
		logo = {
			filename: SubNavigationModuleAdditionalLogo.subLogo[0].filename,
			src: getImageUrl(
				SubNavigationModuleAdditionalLogo.subLogo[0].filename,
			),
			type: "image",
			alt: SubNavigationModuleAdditionalLogo.subLogo[0].title,
			width: SubNavigationModuleAdditionalLogo.subLogo[0].width,
			height: SubNavigationModuleAdditionalLogo.subLogo[0].height,
		}
	}

	const links = SubNavigationModuleSubNavigation.subLink.map(
		(element: any, index: any) => {
			return {
				id: element.id,
				title: element.mainTitle,
				link: linkFormatter(element.linklist),
				asButton: element.asButton,
				asPrimary: element.asPrimary,
			}
		},
	)

	return {
		subLogo: logo,
		links,
	}
}

/**
 * Formatting image structure for  Advanced Side Asset module
 */
export const getAdvancedSideAssetModuleAsset = (
	asset: any,
): undefined | AssetType => {
	if (typeof asset?.image !== "undefined") {
		return {
			filename: asset.image[0].filename,
			src: getImageUrl(asset.image[0].filename),
			type: "image",
			alt: asset.image[0].title,
			width: asset.image[0].width,
			height: asset.image[0].height,
		}
	}
	if (typeof asset?.video !== "undefined") {
		return {
			filename: asset.video,
			src: asset.video,
			type: "video",
			alt: "undefiend",
			width: 1920,
			height: 1080,
		}
	}

	return undefined
}
/**
 *  Creating the structure for Customers Stories posts from backend
 */
export const CustomerStoriesModule = (
	data: ModulesData,
): CustomerStoryModuleType[] => {
	const result: CustomerStoryModuleType[] = []

	data.data.map((element: any, index: number) => {
		result.push(FormatCustomerStory(element))
		return element
	})

	return result
}

/**
 * Formatting single Customer Story post data
 */
export const FormatCustomerStory = (element: any): CustomerStoryModuleType => {
	const previewImage: AssetType = {
		type: "image",
		src: getImageUrl(element.dbCustomersStoriesPreviewImage?.[0]?.filename),
		alt: element.dbCustomersStoriesPreviewImage?.[0]?.title,
		filename: element.dbCustomersStoriesPreviewImage?.[0]?.filename,
		width: element.dbCustomersStoriesPreviewImage?.[0]?.width,
		height: element.dbCustomersStoriesPreviewImage?.[0]?.height,
	}
	const insideVideo: AssetType = {
		type: "video",
		src: element.dbCustomersStoriesVideoLink,
		alt: element.dbCustomersStoriesTitle,
		filename: element.dbCustomersStoriesTitle,
		width: 1920,
		height: 1080,
	}
	const logo: AssetType = {
		type: "image",
		src: getImageUrl(element.dbCustomersStoriesLogo[0].filename),
		filename: element.dbCustomersStoriesLogo[0].filename,
		alt: element.dbCustomersStoriesLogo[0].title,
		width: element.dbCustomersStoriesLogo[0].width,
		height: element.dbCustomersStoriesLogo[0].height,
	}
	const useCase: CategoryModuleType[] = getCategoriesFormat(
		element.dbCustomersStoriesUseCase,
	)
	const industry: CategoryModuleType[] = getCategoriesFormat(
		element.dbCustomerStoriesIndustry,
	)

	return {
		id: element.id,
		title: element.dbCustomersStoriesTitle,
		company: element.dbCustomersStoriesCompany,
		content: element.dbCustomersStoriesDescription?.content,
		link: getLink("customerStories") + "/" + String(element.slug),
		logo,
		industry,
		useCase,
		categories: [
			{industries: {options: industry}},
			{useCases: {options: useCase}},
		],
		preview: previewImage,
		inside: insideVideo,
		slug: element.slug,
	}
}

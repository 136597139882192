import {MenuModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React, {useState} from "react"

import HeaderLink from "../HeaderComponents/HeaderLink"

import styles from "./Navigation.module.scss"
type Props = {
	menu: MenuModuleType[]
}

const Navigation = (props: Props) => {
	// Define a state variable for isShown
	const [isShown, setIsShown] = useState(-1)

	return (
		<nav className={styles.navWrapper}>
			{/* <div className={styles.headerText}>
				Online video software company
			</div> */}
			<ul className={styles.navList} onMouseLeave={() => setIsShown(-1)}>
				{props.menu.map(
					(navBar: MenuModuleType, navBarIndex: number) => {
						const {parent, children} = navBar
						const asLink = !parent && children?.length === 0
						const asExpanded =
							!parent && children && children?.length > 0

						return (
							<React.Fragment key={navBarIndex}>
								{asExpanded && (
									<HeaderLink
										asExpanded={{
											isShown,
											navBarIndex,
											setIsShown,
										}}
										data={navBar}
									/>
								)}
								{asLink && <HeaderLink data={navBar} asLink />}
							</React.Fragment>
						)
					},
				)}
			</ul>
		</nav>
	)
}

export default Navigation

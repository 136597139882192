// ###
// Contain Helping functions for  "./GetComponentDataByKey.tsx "
// To make a code smartest and more splitted
// ###

import {PositionListData} from "@pages/Careers/Positions/PositionsSectionComponent"

import {
	BlogPostModuleType,
	CategoryModuleType,
	CustomerStoryModuleType,
	EventPostModuleType,
} from "./GetComponentDataByKeyTypes"

// Cleaning function to eliminate empty objects from backend
export const getCategoriesFormat = (data: any): CategoryModuleType[] => {
	let categories: CategoryModuleType[] = data.map(
		(element: any, index: any) => {
			let parent = null
			let children = null
			if (element.parent) {
				parent = getCategoriesFormat([element.parent])[0]
			}
			if (element?.children?.length > 0) {
				children = getCategoriesFormat(element.children)
			}
			if (typeof element.id !== "undefined" && parent === null) {
				return {
					id: element.id,
					slug: element.slug,
					parent,
					childrens: children,
					title: element.title,
					uri: element.uri ? element.uri : "",
				}
			}
			return null
		},
	)

	categories = categories.filter((element: any) => element !== null)

	return categories
}

// ! Image format function, always use this one for formatting the data.
export const getImageUrl = (filename: string | undefined): string => {
	// if (typeof filename === "undefined") {
	// 	return "https://picsum.photos/1920/1080"
	// }
	// if (Math.floor(Math.random() * 2) === 1) {
	// 	return "https://picsum.photos/1920/1080"
	// }
	return String("/dreamBroker/") + String(filename)
}

export const checkIfInJobCategory = (
	data: any,
	categories?: string[] | undefined,
): PositionListData => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (
			typeof categories !== "undefined" &&
			categories.includes(element.category[0].slug)
		) {
			result.push(element)
			return element
		} else if (typeof categories === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkIfInBlogCategory = (
	data: any,
	categories?: string[] | undefined,
): BlogPostModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof categories !== "undefined") {
			for (let index = 0; index < element.category.length; index++) {
				if (categories.includes(element.category[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof categories === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkIfCustomerStoryIn = (
	data: any,
	useCase?: string[] | undefined,
	industry?: string[] | undefined,
): CustomerStoryModuleType[] => {
	let result: CustomerStoryModuleType[] = data

	if (useCase) {
		result = checkifInCustomerStoryUseCase(result, useCase)
	}
	if (industry) {
		result = checkifInCustomerStoryIndustry(result, industry)
	}

	return result
}

export const checkIfEventIn = (
	data: any,
	category?: string[] | undefined,
	type?: string[] | undefined,
	location?: string[] | undefined,
): EventPostModuleType[] => {
	let result: EventPostModuleType[] = data

	if (category) {
		result = checkifInEventCategory(result, category)
	}
	if (type) {
		result = checkifInEventType(result, type)
	}
	if (location) {
		result = checkifInEventLocation(result, location)
	}

	return result
}

export const checkifInEventCategory = (
	data: any,
	category?: string[] | undefined,
): EventPostModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof category !== "undefined") {
			for (let index = 0; index < element.category.length; index++) {
				if (category.includes(element.category[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof category === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}
export const checkifInEventType = (
	data: any,
	type?: string[] | undefined,
): EventPostModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof type !== "undefined") {
			for (let index = 0; index < element.type.length; index++) {
				if (type.includes(element.type[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof type === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkifInEventLocation = (
	data: any,
	location?: string[] | undefined,
): EventPostModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof location !== "undefined") {
			for (let index = 0; index < element.location.length; index++) {
				if (location.includes(element.location[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof location === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkifInCustomerStoryUseCase = (
	data: any,
	useCase?: string[] | undefined,
): CustomerStoryModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof useCase !== "undefined") {
			for (let index = 0; index < element.useCase.length; index++) {
				if (useCase.includes(element.useCase[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof useCase === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkifInCustomerStoryIndustry = (
	data: any,
	industry?: string[] | undefined,
): CustomerStoryModuleType[] => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof industry !== "undefined") {
			for (let index = 0; index < element.industry.length; index++) {
				if (industry.includes(element.industry[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof industry === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkIfJobIn = (
	data: any,
	location?: string[] | undefined,
	category?: string[] | undefined,
): PositionListData => {
	let result: PositionListData = data

	if (location) {
		result = checkifInJobLocation(result, location)
	}
	if (category) {
		result = checkifInJobPositions(result, category)
	}

	return result
}

export const checkifInJobLocation = (
	data: any,
	location?: string[] | undefined,
): PositionListData => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof location !== "undefined") {
			for (let index = 0; index < element.location.length; index++) {
				if (location.includes(element.location[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof location === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

export const checkifInJobPositions = (
	data: any,
	category?: string[] | undefined,
): PositionListData => {
	const result: any[] = []

	data.map((element: any, index: number) => {
		if (typeof category !== "undefined") {
			for (let index = 0; index < element.category.length; index++) {
				if (category.includes(element.category[index].slug)) {
					result.push(element)
					return element
				}
			}
		} else if (typeof category === "undefined") {
			result.push(element)
			return element
		}
		return undefined
	})
	return result
}

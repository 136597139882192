import {useLocale} from "@components/Contexts/LocaleContext"
import {capitalizeFirstSymbol} from "@helpers/capitalizeFirstSymbol"
import {translate} from "@helpers/translate"
import {useRouter} from "next/router"
import {FC} from "react"

interface TranslateProps {
	children: string | undefined
	capitalize?: boolean
	capitalizeFirst?: boolean
}

export const Translate: FC<TranslateProps> = ({
	children,
	capitalize = false,
	capitalizeFirst = false,
}) => {
	const router = useRouter()
	const {locale} = router
	const {locale: customeLocale} = useLocale()

	const localeCustom = customeLocale || locale

	const translatedText = children
		? translate(children, localeCustom || "en", capitalizeFirst)
		: children

	return (
		<>
			{capitalize &&
			localeCustom === "en" &&
			typeof translatedText !== "undefined"
				? capitalizeFirstSymbol(translatedText)
				: translatedText}
		</>
	)
}

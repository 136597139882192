import {CookieContext} from "@components/Contexts/CookieContext"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {checkCookie} from "@helpers/checkCookie"
import {getLink} from "@hooks/PagesData/getLink"
import NextLink from "next/link"
import {useContext} from "react"
import ReactGA from "react-ga4"

import LinkType from "./LinkComponent"

const Link = (props: LinkType): any => {
	const targetExternal =
		props?.link?.includes("http") || props?.link?.includes("www")
	const cookies = props?.link?.includes(getLink("cookieSettings"))
	const thirdPartyCookies = checkCookie("thirdPartyCookies")
	const link = cookies ? "" : props.link ? props.link : ""
	const {changeCookieBanner} = useContext(CookieContext)

	const onClick = (data: any) => {
		if (thirdPartyCookies === "1") {
			ReactGA.event({
				category: "Interaction",
				action: "Link onclick",
				label: `${
					data?.target?.outerText
						? String(data.target.outerText)
						: `link: ${props.link}`
				}`,
			})
		}

		if (typeof window !== "undefined" && thirdPartyCookies === "1") {
			// @ts-expect-error
			const _hsq = (window._hsq = window._hsq || [])
			_hsq.push([
				"trackCustomBehavioralEvent",
				{
					name: "pe414257_interaction",
					properties: {
						label: `${
							data?.target?.outerText
								? String(data.target.outerText)
								: `link: ${props.link}`
						}`,
						action: "Link onclick",
					},
				},
			])
		}

		if (cookies) {
			data.preventDefault()
			changeCookieBanner()
		}

		if (props.onClick) {
			props.onClick(data)
		}
	}

	const target =
		typeof props.withoutTarget !== "undefined" ? props.withoutTarget : true

	return (
		<NextLink
			className={props.className}
			href={link}
			target={targetExternal || !target ? "_blank" : ""}
			onClick={onClick}
		>
			<>
				{typeof props.icon !== "undefined" && (
					<NewResponsiveImage {...props.icon} />
				)}
				{props.children}
			</>
		</NextLink>
	)
}

export default Link

import Image from "next/image"
import {useRouter} from "next/router"
import {useEffect, useRef, useState} from "react"
import {CSSTransition} from "react-transition-group"

import styles from "./LanguageSwitcher.module.scss"

type Props = {}

const LanguageSwitcher = (props: Props) => {
	const languages = [
		{code: "en", name: "En"},
		{code: "fi", name: "Fi"},
		{code: "se", name: "Se"},
		{code: "da", name: "Da"},
		{code: "no", name: "No"},
		{code: "nl", name: "Nl"},
		{code: "de", name: "De"},
		{code: "is", name: "Is"},
	]

	const router = useRouter()
	const {locale} = router

	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const menuRef = useRef<HTMLDivElement>(null)

	const changeLanguage = (e: {target: {value: string}}) => {
		const locale = e.target.value
		router
			.push(router.pathname, router.asPath, {locale})
			.then(() => setIsMenuOpen(false))
			.catch((error) => console.error(error))
	}

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsMenuOpen(false)
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [menuRef])

	return (
		<div ref={menuRef} className={styles.languageSwitcher}>
			<div
				className={`${styles.selectedOption} ${
					!isMenuOpen ? "" : styles.active
				}`}
				onClick={toggleMenu}
			>
				<Image
					alt={`${
						languages.find((language) => language.code === locale)
							?.name
					} flag`}
					height={18.75}
					src={`/languageFlags/${
						languages.find((language) => language.code === locale)
							?.code
					}.png`}
					width={24.75}
				/>
				{/* <p className={styles.normal}>
					{
						languages.find((language) => language.code === locale)
							?.name
					}
				</p>
				<p className={styles.mobile}>
					{
						languages.find((language) => language.code === locale)
							?.name
					}
				</p> */}
			</div>
			<CSSTransition
				classNames={{
					enter: styles.enter,
					enterActive: styles.enterActive,
					exit: styles.exit,
					exitActive: styles.exitActive,
				}}
				in={isMenuOpen}
				timeout={200}
				unmountOnExit
			>
				<div className={`${styles.selectMenu}`}>
					{languages.map((language) => (
						<div
							key={language.code}
							className={`${styles.option} ${
								language.code === locale ? styles.active : ""
							}`}
							onClick={() => {
								changeLanguage({target: {value: language.code}})
								setIsMenuOpen(false)
							}}
						>
							<Image
								alt={`${language.name} flag`}
								height={18.75}
								src={`/languageFlags/${language.code}.png`}
								width={24.75}
							/>
						</div>
					))}
				</div>
			</CSSTransition>
		</div>
	)
}

export default LanguageSwitcher

import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import ExternalIcon from "@components/icons/ExternalIcon"
import {FooterModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import Link from "../../../Link/Link"

import styles from "./FooterMiddleListItem.module.scss"

type Props = {
	data: FooterModuleType
}

const FooterMiddleListItem = (props: Props): any => {
	return (
		<>
			<li className={styles.big__list_item}>
				<Paragraph
					className={styles.title}
					content={props.data.menuTitle.toUpperCase()}
					fontWeight={600}
					size={"XS"}
				/>
				<ul className={styles.small__list}>
					{typeof props.data.children !== "undefined" &&
						props.data.children.map(
							(element: FooterModuleType, index: number) => {
								const external = element.link?.includes("http")
								return (
									<li
										key={index}
										className={styles.small__list_item}
									>
										<Link
											className={`${styles.link__style} ${
												external ? styles.external : ""
											}`}
											link={element.link}
										>
											{element.menuTitle}
											{external && (
												<ExternalIcon
													className={styles.svgIcon}
												/>
											)}
										</Link>
									</li>
								)
							},
						)}
				</ul>
			</li>
		</>
	)
}

export default FooterMiddleListItem

import {Translate} from "@components/Theme/Translate/Translate"
import {TypographyType} from "@customTypes/TypographyTypes"
import parse from "html-react-parser"
import React from "react"

import styles from "./Paragraph.module.scss"

export interface ParagraphType extends TypographyType {}

// The Paragraph component
const Paragraph = (props: ParagraphType & JSX.IntrinsicElements["p"]) => {
	const {
		withoutPadding = false,
		center = undefined,
		fontWeight = 400,
		withoutHtml = false,
		content,
		size = "M",
		capitalize = false,
		...restProps
	} = props

	const sizeMap = {
		XXL: styles.paragraph__XXL,
		XL: styles.paragraph__XL,
		L: styles.paragraph__L,
		M: styles.paragraph__M,
		S: styles.paragraph__S,
		XS: styles.paragraph__XS,
		XXS: styles.paragraph__XXS,
	}

	const sizeP = sizeMap[size]

	const padding = withoutPadding ? {padding: "0"} : {}

	const headingStyle: React.CSSProperties = {
		textAlign: center ? "center" : undefined,
		fontWeight,
	}

	const style = [styles.paragraph, sizeP, props.className].join(" ")

	let contentP

	if (typeof content === "string") {
		const cleanContent = content.replace(/<\/?(?:p|h[1-6])[^>]*?>/gim, "")

		if (withoutHtml) {
			const cleanText = cleanContent.replace(
				/<[^>]+>|&[^;]+;/g,
				(match) => {
					return match === "&amp;" ? "&" : ""
				},
			)
			contentP = cleanText
		} else {
			contentP = parse(cleanContent)
		}

		contentP =
			typeof contentP === "string" ? (
				<Translate capitalize={capitalize}>{contentP}</Translate>
			) : (
				contentP
			)
	} else if (typeof content !== "undefined") {
		contentP = content
	}

	const shouldRenderContent = props.children || contentP

	if (shouldRenderContent) {
		return (
			<p
				style={{...headingStyle, ...props.style, ...padding}}
				{...restProps}
				ref={restProps.ref}
				className={style}
				id={props.id}
			>
				{shouldRenderContent}
			</p>
		)
	}

	return <></>
}

export default Paragraph

import Image, {ImageProps} from "next/image"
import React from "react"

import styles from "./NewResponsiveImage.module.scss"

export interface ResponsiveImageProps extends ImageProps {
	containerClassName?: string
	imageClassName?: string
	withoutContainer?: boolean
}

const NewResponsiveImage: React.FC<ResponsiveImageProps> = ({
	containerClassName,
	withoutContainer,
	imageClassName,
	...imageProps
}) => {
	if (withoutContainer) {
		return (
			<Image
				loading={imageProps.loading ? imageProps.loading : "lazy"}
				{...imageProps}
				className={` ${imageClassName || styles.image} `}
				quality={80}
			/>
		)
	}

	return (
		<div className={` ${containerClassName || styles.container}`}>
			<Image
				loading={"lazy"}
				{...imageProps}
				className={` ${imageClassName || styles.image} `}
				quality={80}
			/>
		</div>
	)
}

export default NewResponsiveImage

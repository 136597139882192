import React from "react"

type Props = {
	className: string
}

const ExternalIcon = (props: Props) => {
	return (
		<svg
			className={props.className}
			fill={"none"}
			height={"24"}
			viewBox={"0 0 24 24"}
			width={"24"}
			xmlns={"http://www.w3.org/2000/svg"}
		>
			<g filter={"url(#filter0_b_11_10713)"}>
				<path
					d={
						"M16.9996 20.0002H5.9998C4.89711 20.0002 4 19.1031 4 18.0004V6.9997C4 5.89701 4.89711 4.9999 5.9998 4.9999H10.0003V6.9997H5.9998V18.0004H16.9996V13.9999H19.0003V18.0004C19.0003 19.1031 18.1028 20.0002 16.9996 20.0002ZM11.7004 13.7074L10.2901 12.2926L16.5829 5.9998H13V4H20.0002V11.0002H18.0004V7.4146L11.7004 13.7074Z"
					}
				/>
			</g>
			<defs>
				<filter
					color-interpolation-filters={"sRGB"}
					filterUnits={"userSpaceOnUse"}
					height={"36"}
					id={"filter0_b_11_10713"}
					width={"36.0002"}
					x={"-6"}
					y={"-6"}
				>
					<feFlood
						flood-opacity={"0"}
						result={"BackgroundImageFix"}
					/>
					<feGaussianBlur
						in={"BackgroundImageFix"}
						stdDeviation={"5"}
					/>
					<feComposite
						in2={"SourceAlpha"}
						operator={"in"}
						result={"effect1_backgroundBlur_11_10713"}
					/>
					<feBlend
						in={"SourceGraphic"}
						in2={"effect1_backgroundBlur_11_10713"}
						mode={"normal"}
						result={"shape"}
					/>
				</filter>
			</defs>
		</svg>
	)
}

export default ExternalIcon

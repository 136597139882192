/**
 * Truncating the text
 */
const Truncate = (
	text: string | undefined,
	count: number,
	separator?: string,
): string | undefined => {
	if (typeof text !== "undefined" && text.length > count) {
		return `${text.substring(0, count)}` + (separator ?? "...")
	}
	return text
}

export default Truncate
